<template>
    <div class="bldr-slider-container">
        <div class="bldr-slider-img" v-for="(img,imgI) in images" :key="imgI + 'img'" :style="{backgroundImage:`url(${img})`}">
            <a-icon type="minus-circle" @click="removeImage(imgI)" />
        </div>
        <div class="bldr-slider-img bldr-add" @click="selectMedia" v-if="maxNotReached">
            <a-icon type="plus-circle" />
        </div>
    </div>

</template>

<script>
import {getBase64} from 'bh-mod'
import mime  from 'mime-types'

export default {
    name:'ImageBoxSelectorGroup',
    props:{
        setting:{
            type:Object,
            default: () => {}
        },
        value:{
            type:Array,
            required:true,
            default: () => []
        },
        type:{
            type:String,
            default:'images'
        }
    },
    computed:{
        maxNotReached(){
            if (!this.setting || !this.setting.max) return true 
            return this.images.length < this.setting.max
        }
    },
    data(){
        return {
            images:this.value,
            loading:false,
            fileType:mime.lookup(this.value)
        }
    },
    watch:{
        images(val){
            return this.$emit('input', val)
            let self = this
            let ext = val.split('.')[val.split('.').length -1] 

            let img = document.createElement('img');
            img.onload = () => {
                console.log('width => ',img.naturalWidth);
                console.log('height => ',img.naturalHeight);
                let width = img.naturalWidth
                let height = img.naturalHeight
                if (ext !== 'svg' && (!width || !height) ) {
                    self.img = ''
                    return self.$message.error('Image Error. Not a Valid Image')
                }
                self.$emit('input',val)
                self.$emit('sized',{ext,width,height})
            }
            img.src = val;
            


        }
    },
    methods:{
            removeImage(imgI){
                console.log('REMOVING IMAGE', imgI)
                return this.images = this.images.filter( (x,xI) => xI !== imgI)
            },
         async handleChange(info) {
    
            console.log('INFO FILE', info.file)
            
            this.loading = true
            let formData = new FormData()
            formData.append('files', info.file, info.file.name);
            formData.append('path', this.$bhapp);
            formData.append('instance', this.$store.state.instance.id);

            let avatarUrl = false
            let avatarId = false

                // let okay = false
                // for (var [key, value] of formData.entries()) { 
                //     if (value) okay = true
                //     console.log('THIS FORM DATRA',key, value);
                // }

            let url = `/upload`;
            this.$api.post(url,formData,{headers: { 'Content-Type': 'multipart/form-data' }}).then( ({data}) => {
                this.loading = false
                data = data[0]
                if (data.url){
                    this.img = data.url
                } else {
                    this.$message.error('Oops. Something went wrong.')    
                }
            }).catch(err => {
                this.loading = false
                this.$message.error(this.$err(err))
            })

            // // Get this url from response in real world.
            // getBase64(info.file, imageUrl => {
            //     this.img = imageUrl;
            //     this.loading = false;
            // });
            // this.$emit('fromDrag',this.formData)

        },
        beforeUpload(file) {
            this.loading = true
            const isJPG = file.type === 'image/jpeg';
            const isLtM = file.size / 1024 / 1024 < 5;
            if (!isLtM) {
                this.$message.error('Image must smaller than 5MB!');
                return false
            }
            return false;
        },
        selectedImg(item){
            if (typeof item === 'string') item = {url:item}
            return this.images.push(item.url)
            // this.fileType = mime.lookup(item.url)
            // this.category.icon = item.url
            
        },
        selectMedia(){
            this.$store.commit('MEDIA_SELECT',{callback:this.selectedImg,type:this.type})
        },
        fromLibrary(){
            this.$store.commit('LIBRARY_SELECT',{callback:this.selectedImg,type:'icons'})
        },
    },
    created(){
        this.images = this.value
    },
    mounted(){
        setTimeout(() => {
            let self = this
            
            let dom = this.$el.querySelector('.bh-file-selector input[type=file]')
            if (!dom) return
            delete dom.onclick
            dom.onclick = e => {
                e.preventDefault()
                self.selectMedia()
            }
        }, 1)
    }
}
</script>

<style scoped lang="scss">
    .image-holder-box{
        background-repeat:no-repeat;
        background-size:contain;
        background-position: center;
        width:100px;
        height:100px;
    }
    .bldr-slider-container{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap:20px;
        padding:10px;
        border:1px solid #eee;
        .bldr-slider-img{
            height:0;
            padding-bottom:100%;
            background-size:cover;
            border-radius:4px;
            position: relative;
            background-color:#ccc;
            
            &:not(.bldr-add){
                i{
                    background:red;
                    color: white;
                    opacity:0;
                    transform:translate(0,-50%)
                }
                &:hover{
                    i{
                        opacity:1;
                        transform:translate(-50%,-50%)
                    }

                }
            }
            &.bldr-add{
                cursor: pointer;
                &:hover{
                    background-color:#ddd;

                }
            }
            i{
                background:white;
                border-radius:50%;
                position: absolute;
                left:50%;
                top:50%;
                transform: translate(-50%,-50%);
                transition:transform .3s ease-out, opacity .3s ease-out;
                cursor:pointer;

            }
        }
    }
</style>